import { StationProps } from '../type/StationProps'
import { CustomMarkerProps } from '../type/CustomMarkerProps'
import { MapType } from '../type/MapType'
import { getMarkerIconBasedOnMapType } from './getMarkerIconBasedOnMapType'
import { BulkyType } from '../type/BulkyType'

// helper function to create a marker
export const createMarker = (
    markerObj: StationProps,
    map: google.maps.Map,
    mapType: MapType,
    activeID = 0
) => {
    const coordinates = markerObj.geometry.coordinates
    const isMonitored = !!markerObj.properties.is_monitored
    const isPlacedBulkyWaste = !!markerObj.parentStation
    const isBioBulkyWaste =
        mapType === 'bulky-waste' &&
        markerObj.properties.bulkyType !== BulkyType.default
    const id = markerObj.properties.id
    const isActive = activeID === id

    const iconName = getMarkerIconBasedOnMapType(
        mapType,
        isActive,
        isMonitored,
        isBioBulkyWaste,
        isPlacedBulkyWaste
    )

    const image = {
        // 45x45 size
        url: `/img/layout/${iconName}.svg`,
        size: new google.maps.Size(45, 45),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(22.5, 22.5),
    }

    const marker = new window.google.maps.Marker({
        position: {
            lat: coordinates[1],
            lng: coordinates[0],
        },
        map: map,
        icon: image,
    }) as CustomMarkerProps

    marker.station = markerObj

    return marker
}
