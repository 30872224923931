import { MapType } from '../type/MapType'

export const getMarkerIconBasedOnMapType = (
    type: MapType,
    isActive: boolean,
    isMonitored: boolean,
    isBioBulky: boolean,
    isPlacedBulky: boolean
) => {
    let name = ''
    switch (type) {
        case 'sorted-waste':
            name = 'map-marker-trash'
            break
        case 'bulky-waste':
            name = 'map-marker-bulky'
            break
        case 'waste-collection':
            name = 'map-marker-collection'
            break
        default:
            name = 'map-marker-trash'
            break
    }
    if (isPlacedBulky) {
        name = `${name}-placed`
    }
    isMonitored && (name = `${name}-smart`)
    isBioBulky && (name = `${name}-bio`)
    isActive && (name = `${name}-active`)

    return name
}
