const venzeo = 'https://endpoint-for-operator-ict.venzeo.workers.dev'
const bearer = window.env.REACT_APP_VENZEO_BEARER

export const fetchVenzeo = async () => {
    try {
        const response = await fetch(venzeo, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${bearer}`,
            },
        })
        return await response.json()
    } catch (e) {
        // console.log('Error: ', e)
        return []
        // return [
        //     {
        //         custom_id: '2024-03-11 14:00 ID:85778',
        //         location: {
        //             lat: '50.111460',
        //             lon: '14.332757',
        //             accuracy_in_meters: 4,
        //         },
        //     },
        //     {
        //         custom_id: '2024-03-11 14:00 ID:87630',
        //         location: {
        //             lat: '50.078986',
        //             lon: '14.505899',
        //             accuracy_in_meters: 3,
        //         },
        //     },
        // ]
    }
}
