import React, { useState } from 'react'
import styles from './Banner.module.scss'
import clsx from 'clsx'

export const Banner = () => {
    const [open, setOpen] = useState(true)

    return (
        <div className={clsx(styles.wrapper, open && styles.open)}>
            <a
                className={styles.link}
                href="https://mojepraha.eu/"
                target={'_blank'}
                rel={'noreferrer'}
            />
            <div className={styles.main}>
                <img className={styles.icon} src="/img/icon/modal.svg" alt="" />
                <div className={styles.text}>
                    Stáhněte si plnou verzi{' '}
                    <strong>aplikace Moje Praha zdarma</strong>
                </div>
            </div>
            <img
                className={styles.closer}
                src="/img/icon/modal-closer.svg"
                alt=""
                onClick={() => setOpen(false)}
            />
        </div>
    )
}
