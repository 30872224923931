import { MapType } from '../type/MapType'

const sortedWasteUrl = 'https://api.golemio.cz/v2/sortedwastestations'
const bulkyWasteUrl = 'https://api.golemio.cz/v1/bulky-waste/stations'
const wasteCollectionUrl = 'https://api.golemio.cz/v2/wastecollectionyards'

const token = window.env.REACT_APP_GOLEMIO_TOKEN
const range = window.env.REACT_APP_GOLEMIO_RANGE || 1000
const wasteCollectionRange =
    window.env.REACT_APP_WASTE_COLLECTION_RANGE || 22000

export async function fetchGolemio(type: MapType, params = '') {
    // bulky wastes have range param in kilometers, other types in meters
    const validatedRange =
        type === 'waste-collection'
            ? wasteCollectionRange
            : type === 'bulky-waste'
            ? Number(range) / 1000
            : range
    const rangeQuery = `range=${validatedRange}${
        type === 'sorted-waste' ? '&accessibility=1' : ''
    }`
    const formattedParams =
        params !== '' ? `?${params}&${rangeQuery}` : `?${rangeQuery}`

    // in switch set baseUrl based on the type
    let baseUrl = ''

    switch (type) {
        case 'sorted-waste':
            baseUrl = sortedWasteUrl
            break
        case 'bulky-waste':
            baseUrl = bulkyWasteUrl
            break
        case 'waste-collection':
            baseUrl = wasteCollectionUrl
            break
        default:
            throw new Error('Unknown type')
    }

    try {
        const response = await fetch(`${baseUrl}/${formattedParams}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': String(token),
            },
        })

        return await response.json()
    } catch (e) {
        console.log('Error: ', e)
    }
}
